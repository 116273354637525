'use strict';

angular.module('lmsApp').controller('CruduserController',
    [
        '$scope',
        'Users',
        'Cruduser',
        'SchoolNew',
        'School',
        'Authroles',
        'UserSettings',
        'Locations',
        '$translate',
        'adaptedProgramList',
        'Principal',
        'usSpinnerService',
        '$timeout',
        '$q',
        'rolesMap',
        'rolesMapEdu4',
        'edu4',
        function (
            $scope,
            Users,
            Cruduser,
            SchoolNew,
            School,
            Authroles,
            UserSettings,
            Locations,
            $translate,
            adaptedProgramList,
            Principal,
            usSpinnerService,
            $timeout,
            $q,
            rolesMap,
            rolesMapEdu4,
            edu4
        ) {

            console.log('edu4:', edu4);
            $scope.crudusers = [];
            $scope.user = {
                roles: [],
                info: [],
            };
            $scope.userInfo = {};
            $scope.territories = [];
            $scope.error = {};
            $scope.userSettings = {};
            $scope.settingsAccount = {};
            $scope.userStatus = {
                activated: true,
                disabled: false
            }
            $scope.searchText = '';
            $scope.selectedRole = 'ALL';
            $scope.school;
            $scope.schoolsOptions = {
                'page': 1,
                'per_page': 5,
                'sorting_fields': ['id'],
                'sorting_direction': 'asc'
            };
            $scope.errorText = '';
            $scope.sorting = {
                fields: ['lastName'],
                direction: 'asc'
            };
            $scope.pagination = {
                page: 1,
                total: 0,
                perPage: 20
            };

            $scope.user.s_category = null;

            $scope.adaptedProgramList = adaptedProgramList;

            $scope.roles = [];
            $scope.roleList = [];

            var screenWidth = window.innerWidth;

            $scope.toggleRoles = function toggleRoles(roleName) {
                var idx = $scope.user.roles.indexOf(roleName);
                // is currently selected
                if (idx > -1) {
                    $scope.user.roles.splice(idx, 1);
                }
                // is newly selected
                else {
                    $scope.user.roles.push(roleName);
                }
            };
            //
            // $scope.toggleHiddenRoles = function (roleName) {
            //     var idx = $scope.user.hiddenRoles.indexOf(roleName);
            //     // is currently selected
            //     if (idx > -1) {
            //         $scope.user.hiddenRoles.splice(idx, 1);
            //     }
            //     // is newly selected
            //     else {
            //         $scope.user.hiddenRoles.push(roleName);
            //     }
            // };

            $scope.init = function () {
                var prom1 = Authroles.query(function (result) {
                    $scope.roles = result;
                    console.log( $scope.roles)
                    // ---
                    var roleList;
                    if (edu4) {
                        roleList = result.map(function (roleId) {
                            var text = rolesMapEdu4[roleId] || '????';
                            return {
                                id: roleId,
                                text: text
                            };
                        });
                    } else {
                        roleList = result.map(function (roleId) {
                            var text = rolesMap[roleId] || '????';
                            return {
                                id: roleId,
                                text: text
                            };
                        });
                    }

                    // сортировать значения по алфавиту от А до Я.
                    var roleListSort = roleList.sort(function (itemA, itemB) {
                        if (itemA.text < itemB.text) {
                            return -1;
                        }
                        return 1;
                    });
                    $scope.roleList = roleListSort;
                }).$promise;

                var prom2 = Locations.query({ type: 'TERRITORY' }, function (result) {
                    $scope.territories = result;
                }).$promise;

                $scope.schools = { 'items': [null] };
                var prom3 = $scope.getSchools();

                var prom4 = Principal.identity().then(function (account) {
                    $scope.account = account;
                });

                return $q.all([prom1, prom2, prom3, prom4]);
            };

            $scope.loadAll = function (withSearch, perPage) {
                if (!withSearch) {
                    $scope.searchText = '';
                }
                var prefs = {
                    page: $scope.pagination.page,
                    per_page: perPage ? perPage : $scope.pagination.perPage,
                    search_text: $scope.searchText ? $scope.searchText : '',
                    sorting_fields: $scope.sorting.fields,
                    sorting_direction: $scope.sorting.direction,
                    role: $scope.selectedRole,
                    activated: $scope.userStatus.activated,
                    disabled: $scope.userStatus.disabled,
                };
                Users.getAll(prefs, function (result, headers) {
                    if (perPage && result.length > 20) {
                        $scope.pagination.perPage = perPage
                    }
                    $scope.pagination.total = headers('X-Total-Count');
                    $scope.crudusers = result;
                });
                savePrefs(prefs);
            };

            $scope.changePerPageCount = function (number) {
                $scope.pagination.perPage = number;
                $scope.pagination.page = 1;
                $scope.loadAll(true);
            };

            $scope.sort = function (fields) {
                //Меняем порядок сортировки, если выбран тот же столбец
                if (fields[0] === $scope.sorting.fields[0]) {
                    if ($scope.sorting.direction === 'asc') {
                        $scope.sorting.direction = 'desc';
                    } else {
                        $scope.sorting.direction = 'asc';
                    }
                } else {
                    //Меняем столбец сортировки
                    $scope.sorting.fields = fields;
                    $scope.sorting.direction = 'asc';
                }

                $scope.pagination.page = 1;
                $scope.loadAll(true);
            };

            $scope.createUser = function () {
                $scope.clear();
                $('#saveCruduserModal').modal('show');
            };

            $scope.create = function () {
                $scope.error.loginExists = false;
                $scope.error.noRoles = false;
                $scope.user.schoolId = $scope.school.id;
                $scope.user.info[0] = $scope.userInfo;
                Users.create($scope.user, function (data, status) {
                    $scope.clear();
                    $scope.loadAll();
                    $('#saveCruduserModal').modal('hide');;
                }, function (response, err) {
                    if (response.status === 409) {
                        if (response.data.headers['error_type'] === 'LOGIN_DUPLICATE') {
                            $scope.error.loginExists = true;
                        } else if (response.data.headers['error_type'] === 'LIST_OF_ROLES_IS_EMPTY') {
                            $scope.error.noRoles = true;
                        } else if (response.data.headers['error_type'] === 'WRONG_STUDENT_ROLES') {
                            $scope.error.noCorrectRoles = true;
                        } else {
                            $scope.cruduserError = response.data.headers['error_type'];
                        }
                    }
                });
            };

            $scope.changeRoleViews = function (roleViewName, roleName) {
                if (roleViewName) {
                    $scope.user.roleViews[roleName] = roleViewName;
                }
            };

            $scope.update = function (id) {
                $scope.clear();
                Users.getById({
                    id: id
                }, function (result) {
                    $scope.user = result;
                    $scope.userInfo = $scope.user.info[0];
                    $scope.school = $scope.user.school;

                    $('#updateCruduserModal').modal('show');
                });
            };

            $scope.activation = function (id, activate) {
                Users.updateActivationStatus({}, { id: id, activate: activate }, function (result) {
                    $scope.loadAll(true);
                });
            };

            $scope.updateAccount = function () {

                $timeout(function () {
                    usSpinnerService.spin('spinnerUsersUpdate');
                }, 0);
                $scope.error.loginExists = false;
                $scope.error.noRoles = false;
                $scope.user.schoolId = $scope.school.id;
                delete $scope.user.school;
                // $scope.user.info[0] = $scope.userInfo;
                Users.update($scope.user,
                    function () {
                        if ($scope.user.roles.indexOf($scope.userSettings.currentRole) === -1) {
                            $scope.userSettings.currentRole = $scope.user.roles.pop();
                        }
                        $('#updateCruduserModal').modal('hide');
                        usSpinnerService.stop('spinnerUsersUpdate');
                        $scope.clear();
                        $scope.loadAll(true);
                    },
                    function (response) {
                        if (response.status === 409) {
                            if (response.data.headers['error_type'] === 'LIST_OF_ROLES_IS_EMPTY') {
                                $scope.error.noRoles = true;
                            } else {
                                $scope.cruduserError = response.data.headers['error_type'];
                            }
                        }
                    });
            };

            $scope.delete = function (id) {
                $scope.user.id = id;
                $('#deleteCruduserConfirmation').modal('show');
            };

            $scope.confirmDelete = function (id) {
                Users.deleteById({
                        id: id
                    },
                    function () {
                        $scope.loadAll(true);
                        $('#deleteCruduserConfirmation').modal('hide');
                        $scope.clear();
                    },
                    function (response) {
                        $scope.error.userAssignToGroup = response.data.headers['error_type'];
                    });
            };

            $scope.search = function () {
                var perPage = 50;
                $scope.pagination.page = 1;
                $scope.loadAll(true, perPage);
            };

            $scope.clear = function () {
                $scope.user = {
                    roles: [],
                    info: []
                };
                $scope.userInfo = {};
                $scope.error = {};
                $scope.cruduserError = '';
                $scope.editForm.$setPristine();
                $scope.editForm.$setUntouched();
                $scope.school = null;
            };
            $scope.getSchools = function (loadOptions) {
                loadOptions = (loadOptions) ? loadOptions : $scope.schoolsOptions;
                return SchoolNew.getAll(loadOptions, function (result, headers) {
                    $scope.schools = {
                        'items': result,
                        'total': headers('X-Total-Count'),
                    };
                }).$promise;
            };
            $scope.screenDetector = function () {
                return screenWidth;
            };

            window.addEventListener('resize', function () {
                screenWidth = window.innerWidth;
            });

            $scope.init().then(function () {
                loadPrefs();
                $scope.loadAll(true);
            });

            function savePrefs(prefs) {
                // var prefs = {
                //   page: $scope.pagination.page,
                //   per_page: perPage ? perPage : $scope.pagination.perPage,
                //   search_text: $scope.searchText ? $scope.searchText : '',

                //   sorting_fields: $scope.sorting.fields,
                //   sorting_direction: $scope.sorting.direction,

                //   role : $scope.selectedRole ? $scope.selectedRole : 'ALL',
                //   activated: $scope.userStatus.activated,
                //   disabled: $scope.userStatus.disabled,
                // };
                sessionStorage.setItem("cruduserPage", JSON.stringify(prefs));
            }
            function loadPrefs() {
                var cruduserPage = sessionStorage.getItem("cruduserPage");
                if (!cruduserPage) {
                    return;
                }
                var prefs = JSON.parse(cruduserPage);

                $scope.pagination = $scope.pagination || {};
                $scope.pagination.page = prefs.page;
                $scope.pagination.perPage = prefs.per_page;

                $scope.searchText = prefs.search_text;

                $scope.sorting = $scope.sorting || {};
                $scope.sorting.fields = prefs.sorting_fields;
                $scope.sorting.direction = prefs.sorting_direction;

                $scope.selectedRole = prefs.role;

                $scope.userStatus = $scope.userStatus || {};
                $scope.userStatus.activated = prefs.activated;
                $scope.userStatus.disabled = prefs.disabled;
            }
        }
    ]);
