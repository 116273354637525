'use strict';

angular
  .module('lmsApp')
  .controller(
    'UserGroupDetailController',
    function($scope, $stateParams, Users, UserGroup, User, CourseProgress, innopolis) {
      var search = '';
      $scope.userGroup = {};
      $scope.userList = []; // todo: refactor this part if user number increase, possible memory leak
      $scope.excludedIds = [];
      $scope.userGroupId = $stateParams.id;
      $scope.searchText = '';
      $scope.sorting = {
        fields: ['id'],
        direction: 'asc'
      };
      $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
      };

      $scope.init = function() {
        UserGroup.getOneById(
          {
            id: $scope.userGroupId
          },
          function(group) {
            $scope.userGroup = group;
            $scope.loadAll();
          }
        );
      };

      $scope.loadAll = function(withSearch) {
        if (withSearch) {
          search = $scope.searchText;
        } else {
          $scope.searchText = search;
        }

        Users.getStudentsByGroup(
          {
            groupId: $scope.userGroupId,
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage,
            search_text: search ? search : '',
            sorting_fields: $scope.sorting.fields,
            sorting_direction: $scope.sorting.direction
          },
          function(result, headers) {
            $scope.pagination.total = headers('X-Total-Count');
            savePrefs();
            $scope.userList = result;
          }
        );
      };

      $scope.changePerPageCount = function(number) {
        $scope.userError = '';
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
      };

      $scope.sort = function(fields) {
        //Меняем порядок сортировки, если выбран тот же столбец
        if (fields[0] === $scope.sorting.fields[0]) {
          if ($scope.sorting.direction === 'asc') {
            $scope.sorting.direction = 'desc';
          } else {
            $scope.sorting.direction = 'asc';
          }
        } else {
          //Меняем столбец сортировки
          $scope.sorting.fields = fields;
          $scope.sorting.direction = 'asc';
        }
        $scope.pagination.page = 1;
        $scope.loadAll();
      };
      $scope.search = function() {
        $scope.userError = '';
        $scope.pagination.page = 1;
        $scope.loadAll(true);
      };
      $scope.addUser = function(id) {
        var learinigClass = $scope.userGroup.learningClasses[0].id;
        var user = getUserById(id);
        var isInnopolisBuild = !!innopolis;
        $scope.userGroup.users.push(user);
        if ($scope.userGroup) {
          var usergroup = {
            tutorId: $scope.userGroup.tutor ? $scope.userGroup.tutor.id : null,
            learningClassId: learinigClass,
            name: $scope.userGroup.name,
            innopolis: isInnopolisBuild
          };
          if ($scope.userGroup.id !== undefined && $scope.userGroup.id !== null) {
            usergroup.id = $scope.userGroup.id;
            if ($scope.userGroup.users !== undefined && $scope.userGroup.users.length > 0) {
              usergroup.userIds = $scope.userGroup.users.map(function(user) {
                return user.id;
              });
            }
          }
        }
        UserGroup.update(
          usergroup,
          function(data) {
            $scope.userGroup = data;
            $scope.userError = '';
            if ($scope.userList.length === 1 && $scope.pagination.page !== 1) {
              $scope.pagination.page--;
            }
            if (
              $scope.userList.length === 1 &&
              $scope.pagination.page === 1 &&
              $scope.searchText !== ''
            ) {
              $scope.searchText = '';
            }
            $scope.loadAll();
          },
          function(error) {
            removeUserById(user.id);
            if (error.status === 400) {
              $scope.userError = 'Вы не можете добавить пользователя ' +
                user.firstName +
                ' ' +
                user.lastName +
                ' , так как он уже состоит в другой группе.';
            } else {
              $scope.userError = error.statusText;
            }
          }
        );
      };
      $scope.deleteUser = function(user) {
        var learinigClass = $scope.userGroup.learningClasses[0].id;
          var isInnopolisBuild = !!innopolis;
        removeUserById(user.id);
        if ($scope.userGroup) {
          var usergroup = {
            tutorId: $scope.userGroup.tutor ? $scope.userGroup.tutor.id : null,
            learningClassId: learinigClass,
            name: $scope.userGroup.name,
            innopolis: isInnopolisBuild
          };
          if ($scope.userGroup.id !== undefined && $scope.userGroup.id !== null) {
            usergroup.id = $scope.userGroup.id;
            if ($scope.userGroup.users !== undefined && $scope.userGroup.users.length > 0) {
              usergroup.userIds = $scope.userGroup.users.map(function(user) {
                return user.id;
              });
            }
          }
        }
        $scope.userError = '';
        UserGroup.update(usergroup, function(data) {
          $scope.userGroup = data;
          $scope.userError = '';
          $scope.loadAll();
        });
      }; //     $scope.userError = ''; //     removeUserById(id); //         $scope.userGroup = data; //         updateExcludedIds(); //         $scope.loadAll(); //     }); //     $('#deleteUserConfirmation').modal('hide'); // } // $scope.confirmDelete= function(id){ //         $scope.userError = ''; //     UserGroup.update($scope.userGroup, function (data) {
      function getUserById(id) {
        id = parseInt(id, 10);
        for (var i in $scope.userList) {
          if ($scope.userList[i].id && $scope.userList[i].id === id) {
            return $scope.userList[i];
          }
        }
        return null;
      }
      function removeUserById(id) {
        id = parseInt(id, 10);
        for (var n = 0; n < $scope.userGroup.users.length; n++) {
          if ($scope.userGroup.users[n].id === id) {
            $scope.userGroup.users.splice(n, 1);
            return;
          }
        }
      }
      function loadPrefs() {
        var stSort = JSON.parse(localStorage.getItem('userGroupOwnerSort')),
          stPagination = JSON.parse(localStorage.getItem('userGroupOwnerPagination')),
          stSearch = sessionStorage.getItem('userGroupOwnerSearch'),
          stPage = sessionStorage.getItem('userGroupOwnerPage');
        if (stSort) {
          $scope.sorting = stSort;
        }
        if (stPagination) {
          $scope.pagination = stPagination;
          $scope.pagination.page = 1;
        }
        if (stSearch) {
          search = stSearch;
        }
        if (stPage) {
          $scope.pagination.page = stPage;
        }
      }
      function savePrefs() {
        localStorage.setItem('userGroupOwnerSort', JSON.stringify($scope.sorting));
        localStorage.setItem('userGroupOwnerPagination', JSON.stringify($scope.pagination));
        sessionStorage.setItem('userGroupOwnerSearch', search);
        sessionStorage.setItem('userGroupOwnerPage', $scope.pagination.page);
      }
      $scope.init();
    }
  );
