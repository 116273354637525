/**
 * Created by ablokhin on 11.08.2015.
 */
'use strict';
/**
 * Директива, для перевода системных ролей на странице "Пользователи"
 */
angular.module('lmsApp')
    .directive('translateRole', [
        '$translate',
        'edu4',
        function ($translate, edu4) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    attrs.$observe('translateRole', function (roleName) {
                        if ($translate.instant('lmsApp.cruduser.roles.' + roleName).indexOf(roleName) === -1) {
                            if (edu4) {
                                return element.text($translate.instant('lmsApp.cruduser.rolesEdu4.' + roleName));
                            }
                            return element.text($translate.instant('lmsApp.cruduser.roles.' + roleName));
                        } else {
                            return element.text(roleName);
                        }
                    });
                }
            };
        }
    ]);