(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "Update file deploy.yml",
	"commit": "4a637fd7799e4f60c1c1d7827a85b722072d3380",
	"branch": "Detached: 4a637fd7799e4f60c1c1d7827a85b722072d3380",
	"date": "2023-12-27T16:13:00.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
        .constant('innopolis_stage', undefined)
        .constant('edu4', undefined)
        .constant('bit', true)
;
})();
